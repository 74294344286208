(<template>
  <div class="documents">
    <div v-for="document in documentsForSigning"
         :key="document.id"
         class="documents__item">
      <p class="documents__item-name">{{ document.name }}</p>
      <button :disabled="isDisabledBtns"
              class="sk-btn sk-btn--default documents__btn"
              @click="openSigningModal(document)">{{ $gettext('View and sign') }}</button>
    </div>
  </div>
</template>)

<script>
  import {mapState} from 'vuex';

  export default {
    props: {
      isDisabledBtns: Boolean
    },
    computed: {
      ...mapState('TTSignaturesStore', {
        documentsForSigning: (state) => state.documentsForSigning
      })
    },
    methods: {
      openSigningModal(document) {
        this.$store.commit('ModalStore/setModal', {
          component: 'sign-doc-modal',
          data: {
            title: document.name,
            data: {document}
          }
        });
      }
    }
  };
</script>

<style>
  .signatures .signatures__description {
    margin-bottom: 20px;
  }
</style>

<style scoped>
  .documents {
    margin: 0 -20px;
    padding: 0 20px;
    border-top: 1px solid #d3d5de;
  }

  .documents__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }

  .documents__btn {
    width: auto;
    min-width: 141px;
    padding: 0 25px;
  }

  .documents__item-name {
    font-weight: bold;
    font-size: 12px;
  }

  @media (max-width: 767px) {
    .documents {
      margin: 0 -15px;
      padding: 0 15px;
    }
  }
</style>
