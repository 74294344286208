(<template>
  <div>
    <page-data-section :title="sectionTitle"
                       :description="sectionDescription"
                       :progress="progressActive"
                       class="signatures">
      <docs-for-signing :is-disabled-btns="disabledSignButtons" />
    </page-data-section>
    <section class="signatures__table">
      <page-data-tabs :tabs="tabs"
                      :active-tab-id="activeTab"
                      @tabchanged="setActiveTab" />
      <page-data-table :data="tableData"
                       :cells="tableCells"
                       :progress="progressActive"
                       class="signatures__doc-table"
                       @download="catchDownload" />
    </section>
  </div>
</template>)

<script>
  import {mapActions, mapState} from 'vuex';
  import PageDataSection from '@/components/shared_components/page_data/PageDataSection';
  import PageDataTable from '@/components/shared_components/page_data/PageDataTable';
  import PageDataTabs from '@/components/shared_components/page_data/PageDataTabs';
  import DocumentsForSigning from '@/components/documents/signatures/DocumentsForSigning';

  export default {
    asyncData({store}) {
      let promisesArr = [];

      if (!store.state.TTSignaturesStore.documentsForSigning) {
        promisesArr = [...promisesArr, store.dispatch('TTSignaturesStore/getDocumentsForSigning')];
      }
      if (!store.state.TTSignaturesStore.activeSignatures && !store.state.TTSignaturesStore.inactiveSignatures) {
        promisesArr = [...promisesArr, store.dispatch('TTSignaturesStore/getSignatures')];
      }

      return promisesArr ? Promise.all(promisesArr) : promisesArr;
    },
    components: {
      'page-data-section': PageDataSection,
      'page-data-table': PageDataTable,
      'page-data-tabs': PageDataTabs,
      'docs-for-signing': DocumentsForSigning
    },
    data() {
      return {
        progressActive: false,
        activeTab: 1,
        disabledSignButtons: false
      };
    },
    computed: {
      ...mapState('TTSignaturesStore', {
        inactiveSignatures: (state) => state.inactiveSignatures || [],
        activeSignatures: (state) => state.activeSignatures || []
      }),
      sectionTitle() { return this.$gettext('My signed documents'); },
      sectionDescription() { return this.$gettext('<p class="signatures__description">This page shows all the documents that you have signed and provided to us. Some of these documents are required by our customers before they will agree to work with you.</p><p class="signatures__description">Please take a look the documents, understand their content and then sign if you agree.</p>'); },
      tableCells() {
        return [
          {
            name: this.$gettext('Nr'),
            dataField: 'id',
            type: 'itemNumber'
          },
          {
            name: this.$gettext('Doc. title'),
            dataField: 'name',
            type: 'text'
          },
          {
            name: this.$gettext('Status'),
            dataField: 'status',
            type: 'status',
            statusList: [
              {id: 'pending', text: this.$gettext('Please wait')},
              {id: 'approved', text: this.$gettext('Approved')},
              {id: 'rejected', text: this.$gettext('Rejected')},
            ]
          },
          {
            name: this.$gettext('Download'),
            dataField: 'downloadLink',
            type: 'download'
          },
          {
            name: this.$gettext('Expires'),
            dataField: 'expires',
            type: 'date'
          }
        ];
      },
      tableData() {
        return this.activeTab === 1 ? this.activeSignatures : this.inactiveSignatures;
      },
      tabs() {
        return [{id: 1, name: this.$gettext('Signed')},
                {id: 2, name: this.$gettext('Expired/Rejected')}];
      }
    },
    watch: {
      $route(newVal) {
        if (newVal.params.lang) {
          this.refetchData();
        }
      }
    },
    methods: {
      ...mapActions('TTSignaturesStore', ['getSignature']),
      setDisablingButtons(value) {
        this.disabledSignButtons = value;
      },
      catchDownload(id) {
        this.getSignature(id).then((link) => {
          window.location.href = link;
        });
      },
      setActiveTab(tab) {
        this.activeTab = tab.id;
      },
      refetchData() {
        this.progressActive = true;
        this.$store.dispatch('TTSignaturesStore/getSignatures').then(() => {
          this.progressActive = false;
        });
      },
      openAddressErrorModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'address-error-modal',
          width: 400,
          disableClose: true,
          data: {context: this}
        });
      }
    },
    beforeDestroy() {
      this.$store.commit('TTSignaturesStore/clearStore');
    },
    mounted() {
      if (this.dataPromise) {
        this.dataPromise.then((data) => {
          data.forEach((dataItem) => {
            if (dataItem
              && dataItem.errors
              && dataItem.errors.length
              && dataItem.errors.includes('Address does not exist')) {
              this.setDisablingButtons(true);
              this.openAddressErrorModal();
            }
          });
        });
      }
    }
  };
</script>

<style>
  .signatures .signatures__description {
    margin-bottom: 20px;
  }

  .signatures .page-data-table__cell----pending {
    color: #34c0cc;
  }

  .signatures .page-data-table__cell----approved {
    color: #ff5b24;
    color: var(--color-secondary);
  }

  .signatures .page-data-table__cell----rejected {
    color: #ff3369;
  }
</style>

<style scoped>
  .signatures__table {
    margin-top: 20px;
  }

  .signatures__doc-table {
    font-size: 12px;
  }
</style>
