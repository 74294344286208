<template>
  <div class="page-data-tabs">
    <button v-for="tab in tabs"
            :key="tab.id"
            :class="[tab.classes, {'page-data-tabs__tab--active': tab.id === activeTabId}]"
            class="page-data-tabs__tab"
            @click="setActiveTab(tab)">
      <span class="page-data-tabs__tab-text"
            v-html="tab.name"></span>
    </button>
  </div>
</template>

<script>
  export default {
    props: {
      tabs: {
        type: Array,
        required: true
      },
      activeTabId: {
        type: [String, Number],
        required: true
      }
    },
    methods: {
      setActiveTab(tab) {
        this.$emit('tabchanged', tab);
      }
    }
  };
</script>

<style scoped>
  .page-data-tabs {
    display: flex;
  }

  .page-data-tabs__tab {
    display: block;
    padding: 10px 10px 5px;
    border-bottom: 5px solid transparent;
    font-weight: bold;
    font-size: 18px;
    text-transform: capitalize;
  }

  .page-data-tabs__tab:focus {
    margin-bottom: 2px;
    outline: var(--default-button-focus-outline);
  }

  .page-data-tabs__tab--active {
    border-bottom: 5px solid var(--color-secondary);
    color: var(--color-secondary);
  }
</style>
